/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Stripe = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  height: 188px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @media screen and (max-width: 991px) {
    height: 150px;
    width: 2000px;
    left: -500px;
  }

  @media screen and (max-width: 576px) {
    height: 150px;
    width: 100vw;
    left: 0;
  }
`

const Title = styled(ParseContent)`
  & h2 {
    font-size: ${(props) => props.theme.font.size[40]};
    font-weight: ${(props) => props.theme.font.weight.s};
  }
`

const SubTitle = styled(ParseContent)`
  position: relative;
  z-index: 2;

  & h2 {
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size[40]};
    font-weight: ${(props) => props.theme.font.weight.s};
  }

  & h4 {
    color: ${(props) => props.theme.color.text.light};
  }

  @media screen and (max-width: 1199px) {
    & h2 {
      font-size: ${(props) => props.theme.font.size[30]};
    }
  }

  @media screen and (max-width: 991px) {
    & h2 {
      font-size: ${(props) => props.theme.font.size[40]};
    }
  }

  @media screen and (max-width: 360px) {
    & h2 {
      font-size: ${(props) => props.theme.font.size[30]};
    }
  }
`

const ImageContainer = styled.div`
  height: 160px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 991px) {
    height: 140px;
  }
`

const StyledImage = styled(Plaatjie)`
  position: absolute !important;
  bottom: 10px;
  width: 160px;
  height: auto;
`

const StripeImage = styled(Plaatjie)`
  height: 100%;
  opacity: 0.18;
`

interface ProductWithUspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ProductWithUsps
}

const ProductWithUsps: React.FC<ProductWithUspsProps> = ({ fields }) => {
  const {
    backgroundImage,
    // eslint-disable-next-line no-undef
  } = useStaticQuery<GatsbyTypes.productWithUspsBackgroundImageQuery>(graphql`
    query productWithUspsBackgroundImage {
      backgroundImage: file(
        relativePath: { eq: "home_header_background.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
    }
  `)

  return (
    <div>
      <div className="d-flex justify-content-center pt-5 pb-lg-5 pb-3">
        <Title content={fields.description} />
      </div>
      <div className="position-relative">
        <Stripe className="d-lg-block d-none">
          <StripeImage image={{ localFile: backgroundImage }} alt="" />
        </Stripe>
        <div className="container">
          <div className="row py-3">
            {fields?.products?.map((product, index: number) => (
              <div
                key={index}
                className="position-relativ mb-lg-0 mb-4 pt-lg-0 pt-4 col-lg-4"
              >
                <Stripe className="d-lg-none d-block">
                  <StripeImage image={{ localFile: backgroundImage }} alt="" />
                </Stripe>
                <SubTitle content={product?.title} />
                <ImageContainer className="d-flex justify-content-end position-relative">
                  <StyledImage image={product?.image} alt="" />
                </ImageContainer>
                <ParseContent content={product?.description} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductWithUsps
